<template>
  <div v-if="show">
    <div :class="compeleted ? 'load-complete' : ''" class="circle-loader">
      <div :class="compeleted ? '' : 'hidden'" class="checkmark draw"></div>
    </div>
  </div>
</template>
<script>
import {
  BSpinner,
  BCard,
  BButton,
  BRow,
  BCol,
  BTabs,
  BTab,
} from "bootstrap-vue";
export default {
  components: {
    BSpinner,
    BCard,
    BButton,
    BRow,
    BCol,
    BTabs,
    BTab,
  },
  async created() {},
  data() {
    return {
      show: false,
      compeleted: false,
    };
  },
  computed: {},
  methods: {
    loading() {
      this.compeleted = false;
      this.show = true;
        this.$emit("loading")
    },
    loadCompelete() {
      this.compeleted = true;
      setTimeout(() => {
        this.show = false;
        this.$emit("loadCompelete")
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
$brand-success: #5cb85c;
$loader-size: 2em;
$check-height: $loader-size/2;
$check-width: $check-height/2;
$check-left: ($loader-size/7 + $loader-size/12);
$check-thickness: 2px;
$check-color: $brand-success;

.circle-loader {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: $check-color;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: $loader-size;
  height: $loader-size;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: $check-color;
  transition: border 500ms ease-out;
}

.checkmark {
  &.hidden {
    display: none;
  }
  &.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
  }

  &:after {
    opacity: 1;
    height: $check-height;
    width: $check-width;
    transform-origin: left top;
    border-right: $check-thickness solid $check-color;
    border-top: $check-thickness solid $check-color;
    content: "";
    left: $check-left;
    top: $check-height;
    position: absolute;
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}
</style>