<template>
  <div v-if="hasNoLocations">
    <template>
      <!--
      <b-card class="p-1">
        <div v-if="admin" class="d-flex justify-content-center flex-column">
          <h3 class="text-center text-muted">
            {{ $t("Generic.NoLocations") }}
          </h3>
          <h6 class="text-center text-muted">
            {{ $t("Generic.CreateLocationInfo") }}
          </h6>
        </div>
        <div v-else class="d-flex justify-content-center flex-column">
          <h3 class="text-center text-muted">
            {{ $t("Generic.NoLocationsUser") }}
          </h3>
        </div>
      </b-card>
      -->
    </template>
  </div>
  <div v-else>
    <location-picker-bar
      v-if="selectedLocation"
      :locations="locations"
      :includeCreateLocation="false"
      :includeCreateInfoCard="false"
      v-model="selectedLocation"
    />
    <transition name="fade" mode="out-in" v-if="selectedLocation">
      <div class="accordion">
        <b-card
          no-body
          class="mb-1"
          v-for="infocard in selectedLocation.infoCards"
          :key="infocard.id"
        >
          <b-card-header class="p-1">
            <b-button
              block
              v-b-toggle="infocard.id"
              variant="light"
              class="text-left"
              >{{ infocard.name }}</b-button
            >
          </b-card-header>
          <b-collapse
            :id="infocard.id"
            @show="activeGroup = infocard.id"
            :visible="activeGroup == infocard.id"
          >
            <b-card-body>
              <agreement-basis-group-table
                :infocard="infocard"
              ></agreement-basis-group-table>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import loader from "@/components/layout/loader.vue";
import AgreementBasisGroupTable from "@/views/economics/agreementBasis/agreementBasisGroupTable.vue";
import LocationPickerBar from "@/components/LocationPickerBar.vue";
import authHelper from "@/auth";

import {
  BSpinner,
  BCard,
  BButton,
  BCardHeader,
  BCollapse,
  VBToggle,
  BCardBody,
  BFormGroup,
  BFormCheckbox,
} from "bootstrap-vue";
export default {
  components: {
    BSpinner,
    BCard,
    BButton,
    VBToggle,
    loader,
    BCardHeader,
    BCollapse,
    BCardBody,
    BFormGroup,
    BFormCheckbox,
    LocationPickerBar,
    AgreementBasisGroupTable,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  async created() {
    await this.fetchLocations({ include: ["InfoCards"] });
  },
  data() {
    return {
      admin: authHelper.isAdmin(),

      activeGroup: null,
      selectedLocation: null,
    };
  },
  computed: {
    ...mapGetters({ locations: "locationsOld/list" }),
    hasNoLocations() {
      return !(this.locations && this.locations.length > 0);
    },
  },
  methods: {
    ...mapActions({
      fetchLocations: "locationsOld/fetchList",
    }),
  },
  watch: {
    locations(newValue, oldValue) {
      if (newValue && newValue.length) this.selectedLocation = newValue[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  position: relative;
  .loader {
    position: absolute;
    top: -15px;
    right: 0;
  }
}
.row {
  align-items: end;
}
.rowitem {
  &.offset-top {
    &:first-of-type {
      margin-top: 0;
    }
    margin-top: 10px;
  }
}
.offset-top {
  margin-top: 20px;
}
.card-body {
  padding: 0 15px;
}
</style>