<template>
  <div>
    <confirm-modal :refname="infocard.id" :ref="infocard.id" />

    <loader class="loader" ref="loader"></loader>
    <b-button
      @click="addAgreement"
      variant="outline-primary"
      class="create-btn"
    >
      {{ T("Web.Generic.Add", "Add") }}
    </b-button>
    <b-table
      class="agreementBasisTable"
      :responsive="true"
      :fields="fields"
      :items="infocard.agreements"
    >
      <template #cell(activeFrom)="data">
        <input
          size="sm"
          :disabled="data.item.active"
          @change="
            data.item.activeFrom = $event.target.valueAsDate;
            updateAgreement(data.item);
          "
          class="form-control"
          type="date"
          :value="
            data.item.activeFrom == '0001-01-01T00:00:00'
              ? null
              : format_date(data.item.activeFrom, 'YYYY-MM-DD')
          "
        />
      </template>
      <template #cell(devilationTolerance)="data">
        <b-form-input
          :disabled="data.item.active"
          @change="updateAgreement(data.item)"
          class="form-control"
          type="number"
          v-model="data.item.devilationTolerance"
        />
      </template>
      <template #cell(goodsPurchaseCost)="data">
        <b-form-input
          size="sm"
          :disabled="data.item.active"
          @change="updateAgreement(data.item)"
          class="form-control"
          type="number"
          v-model="data.item.goodsPurchaseCost"
        />
      </template>
      <template #cell(repairPurchaseCost)="data">
        <b-form-input
          size="sm"
          :disabled="data.item.active"
          @change="updateAgreement(data.item)"
          class="form-control"
          type="number"
          v-model="data.item.repairPurchaseCost"
        />
      </template>
      <template #cell(suppliesPurchaseCost)="data">
        <b-form-input
          size="sm"
          :disabled="data.item.active"
          @change="updateAgreement(data.item)"
          class="form-control"
          type="number"
          v-model="data.item.suppliesPurchaseCost"
        />
      </template>
      <template #cell(exchangeCost)="data">
        <b-form-input
          size="sm"
          :disabled="data.item.active"
          @change="updateAgreement(data.item)"
          class="form-control"
          type="number"
          v-model="data.item.exchangeCost"
        />
      </template>
      <template #cell(registrationFeeCost)="data">
        <b-form-input
          size="sm"
          :disabled="data.item.active"
          @change="updateAgreement(data.item)"
          class="form-control"
          type="number"
          v-model="data.item.registrationFeeCost"
        />
      </template>
      <template #cell(oilSurchargeFeeCost)="data">
        <b-form-input
          size="sm"
          :disabled="data.item.active"
          @change="updateAgreement(data.item)"
          class="form-control"
          type="number"
          v-model="data.item.oilSurchargeFeeCost"
        />
      </template>
      <template #cell(rentCost)="data">
        <b-form-input
          size="sm"
          :disabled="data.item.active"
          @change="updateAgreement(data.item)"
          class="form-control"
          type="number"
          v-model="data.item.rentCost"
        />
      </template>
      <template #cell(chargeCost)="data">
        <b-form-input
          size="sm"
          :disabled="data.item.active"
          @change="updateAgreement(data.item)"
          class="form-control"
          type="number"
          v-model="data.item.chargeCost"
        />
      </template>
      <template #cell(wasteCollectionCost)="data">
        <b-form-input
          size="sm"
          :disabled="data.item.active"
          @change="updateAgreement(data.item)"
          class="form-control"
          type="number"
          v-model="data.item.wasteCollectionCost"
        />
      </template>
      <template #cell(income)="data">
        <b-form-input
          size="sm"
          :disabled="data.item.active"
          @change="updateAgreement(data.item)"
          class="form-control"
          type="number"
          v-model="data.item.income"
        />
      </template>
      <template #cell(wrongSortingCost)="data">
        <b-form-input
          size="sm"
          :disabled="data.item.active"
          @change="updateAgreement(data.item)"
          class="form-control"
          type="number"
          v-model="data.item.wrongSortingCost"
        />
      </template>
      <template #cell(futileDrivingCost)="data">
        <b-form-input
          size="sm"
          :disabled="data.item.active"
          @change="updateAgreement(data.item)"
          class="form-control"
          type="number"
          v-model="data.item.futileDrivingCost"
        />
      </template>
      <template #cell(deliveryAndRepatriationCost)="data">
        <b-form-input
          size="sm"
          :disabled="data.item.active"
          @change="updateAgreement(data.item)"
          class="form-control"
          type="number"
          v-model="data.item.deliveryAndRepatriationCost"
        />
      </template>
      <template #cell(action)="data">
        <b-button
          v-if="!data.item.active"
          @click="removeAgreement(data.item)"
          variant="outline-danger"
          size="sm"
        >
          {{ T("Web.Generic.Remove") }}
        </b-button>
      </template>
    </b-table>
  </div>
</template>
<script>
import { BTable, BFormInput, BButton, BFormDatepicker } from "bootstrap-vue";
import loader from "@/components/layout/loader.vue";
import ResourceService from "@/services/base/resource.service";
import { uuid } from "vue-uuid";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";
import { number } from "echarts";

export default {
  components: {
    BTable,
    loader,
    BFormInput,
    BFormDatepicker,
    BButton,
    ConfirmModal,
  },
  props: ["infocard"],
  directives: {},
  data() {
    return {
      services: null,
      saving: false,
      fields: [
        {
          key: "activeFrom",
          label: this.T("Web.Generic.ActiveFrom", "Active from"),
        },
        {
          key: "devilationTolerance",
          label: this.T("Web.Generic.Tolerance", "Tolerance"),
        },

        {
          key: "goodsPurchaseCost",
          label: this.T("Web.Generic.PostingCategories.GoodsPurchase"),
        },
        {
          key: "repairPurchaseCost",
          label: this.T("Web.Generic.PostingCategories.RepairPurchase"),
        },
        {
          key: "suppliesPurchaseCost",
          label: this.T("Web.Generic.PostingCategories.SuppliesPurchase"),
        },
        {
          key: "exchangeCost",
          label: this.T("Web.Generic.PostingCategories.Exchange"),
        },
        {
          key: "registrationFeeCost",
          label: this.T("Web.Generic.PostingCategories.RegistrationFee"),
        },
        {
          key: "oilSurchargeFeeCost",
          label: this.T("Web.Generic.PostingCategories.OilSurchargeFee"),
        },
        {
          key: "rentCost",
          label: this.T("Web.Generic.PostingCategories.Rent"),
        },
        {
          key: "chargeCost",
          label: this.T("Web.Generic.PostingCategories.Charge"),
        },
        {
          key: "wasteCollectionCost",
          label: this.T("Web.Generic.PostingCategories.WasteCollection"),
        },
        {
          key: "income",
          label: this.T("Web.Generic.PostingCategories.Income"),
        },
        {
          key: "wrongSortingCost",
          label: this.T("Web.Generic.PostingCategories.WrongSorting"),
        },
        {
          key: "futileDrivingCost",
          label: this.T("Web.Generic.PostingCategories.FutileDriving"),
        },
        {
          key: "deliveryAndRepatriationCost",
          label: this.T("Web.Generic.PostingCategories.DeliveryOrRepatriation"),
        },
        {
          key: "action",
          label: "",
        },
      ],
    };
  },
  created() {
    this.services = new ResourceService("info-cards");
  },
  mounted() {},
  methods: {
    async updateAgreement(agreementBasis) {
      this.$refs.loader.loading();

      await this.services.updateFromUrl(
        `${this.infocard.id}/agreenemtBasis/${agreementBasis.id}`,
        agreementBasis
      );
      this.$refs.loader.loadCompelete();
    },
    async removeAgreement(agreementBasis) {
      if (
        !(await this.$refs[this.infocard.id].confirm(
          this.T("Web.Generic.ConfirmDelete", "Confirm your delete")
        ))
      )
        return;
      this.$refs.loader.loading();

      await this.services.delete(
        `${this.infocard.id}/agreenemtBasis/${agreementBasis.id}`
      );

      let index = this.infocard.agreements.indexOf(agreementBasis);
      this.infocard.agreements.splice(index, 1);
      this.$refs.loader.loadCompelete();
    },
    async addAgreement() {
      this.$refs.loader.loading();
      let agreementBasis = {
        id: uuid.v4(),
        RentCost: null,
        ChargeCost: null,
        WasteCollectionCost: null,
        Income: null,
        PurchaseCost: null,
        WrongSortingCost: null,
        FutileDrivingCost: null,
        DeliveryAndRepatriationCost: null,
        DevilationTolerance: null,
        ActiveFrom: new Date("0001-01-01T00:00:00Z"),
      };

      await this.services.createFromUrl(
        `${this.infocard.id}/agreenemtBasis/${agreementBasis.id}`,
        {}
      );

      this.infocard.agreements.push(agreementBasis);
      this.$refs.loader.loadCompelete();
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.create-btn {
  margin-bottom: 5px;
}
.loader {
  position: absolute;
  top: 19px;
  right: 22px;
}
.agreementBasisTable {
  input {
    min-width: 100px;
  }
}
</style>
<style>
.agreementBasisTable .table th,
.agreementBasisTable .table td {
  padding: 10px !important ;
}

.agreementBasisTable .table th {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>